<template>
<v-main>
    <v-container fluid>
        <router-view />
    </v-container>
</v-main>
</template>

<script>
export default {
    name: 'DashboardCoreView',

    components: {
    },
}
</script>
